/* eslint-disable react/no-danger */

import type { HTMLWrapperProp } from './HTMLWrapperProps';

import STYLES from './HTMLWrapper.module.scss';

const HTMLWrapper = (props: HTMLWrapperProp) => {
  const { componentName, content } = props;
  const uniqueId = componentName ?? '';
  return (
    <div id={uniqueId} className="html-wrapper">
      {typeof content === 'string' ? (
        <div dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        [
          content.map((blurb: string) => [
            <div key={blurb}>
              <div
                className={STYLES.HTMLWrapper}
                dangerouslySetInnerHTML={{ __html: blurb }}
              />
              <br />
            </div>,
          ]),
        ]
      )}
    </div>
  );
};
export default HTMLWrapper;
